import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import Img from 'gatsby-image'
import { Container, Section, Columns, Column, Content, Breadcrumb, BreadcrumbItem } from 'bloomer';

export default class ProjectTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;

    const template = (
      <Layout>
        <Helmet>
          <title>{`${post.frontmatter.firstname} ${post.frontmatter.surname} | ${config.siteTitle}`}</title>
        </Helmet>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/about/">About</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/about/people">People</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>{post.frontmatter.firstname}</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 class="title is-1">
              {post.frontmatter.firstname} {post.frontmatter.surname}
            </h1>
            <p class="subtitle is-5">{post.frontmatter.job}</p>
            <hr />
            <Columns>
              <Column isSize={{ desktop: 3 }}>
                <Img fluid={post.frontmatter.photo.childImageSharp.thumbnail} />
              </Column>
              <Column>
                <Content
                  renderAs="article"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                ></Content>
              </Column>
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
    return template
  }
}

export const pageQuery = graphql`
  query PersonBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      fields {
          slug
      }
      frontmatter {
          firstname
          surname
          job
          photo {
            childImageSharp {
              thumbnail: fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
  }
`;
